import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/MainPage.vue'),
  },
  // {
  //   path: '/main',
  //   name: 'main',
  //   component: () => import('../pages/MainPage.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
